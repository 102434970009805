export default class User{
  constructor(param) {

    const {
      email,
      eotoken,
      provider,
      name,
      nick,
      birth,
      hp,
      push,
      major,
      university,
      department,
      qu_type,
      image,
      profile_image,
      mb_lecture
    } = param

    //이메일
    this.email = email ?? ''
    //토큰
    this.token = eotoken ?? ''
    //sns
    this.provider = provider ?? ''
    //성명
    this.name = name ?? ''
    //닉네임
    this.nick = nick ?? ''
    //생년월일
    this.birth = birth ?? ''
    //휴대폰번호
    this.hp = hp ?? ''
    //푸시수신 동의
    this.isPush = push === 'Y'
    //전공
    this.major = major ?? '방사선사'
    //대학교
    this.university = university ?? ''
    //학과
    this.department = department ?? ''
    this.qu_type = qu_type ?? ''
    //학생증 이미지
    this.image = image ?? ''
    //프로필이미지
    this.profile_image = profile_image ?? ''
    //강의동영상
    this.mb_lecture = mb_lecture ?? 'N'
  }
}