import qs from 'qs';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import axios from '@/api/axios';
import _ from 'lodash';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {
    page:1,
    totalPage:1,
    list: [],
    view: {}
  },
  isRead: false,
  lecture: ''
}

deepFreeze(initialState)
export const DO_LIST = 'lecture/doList'
export const DO_VIEW = 'lecture/doView'
export const GET_LIST = 'lecture/getList'
export const GET_VIEW = 'lecture/getView'
export const RESET = 'lecture/reset'
export const GET_LECTURE = 'lecture/getLecture'
export const FETCH_DATA = 'lecture/fetchData'

export const lecture = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    fetchData({commit, state}){
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_lecture.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('setLecture', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doList({commit, state}){
      //강의동영상 리스트 조회
      if (state.page > state.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_lecture_list.php',
        params: {
          'page': state.page
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('lectureList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doView({commit, state}, no){
      //강의동영상 상세 조회

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_lecture_view.php',
        params: {
          'no': no
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            state.status = {
              ...state.status,
              view: finalData
            }
            return Promise.resolve(mapKeysCamelCase(response.data))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    lectureList(state, param) {
      //강의동영상 리스트
      state.status = {
        ...state.status,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: _.merge(state.status.list, param.list)
      }
    },
    setLecture(state, param){
      state.lecture = param.mb_lecture
    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
  },
  getters:{
    getLecture(state){
      return state.lecture
    },
    getList(state){
      //강의동영상 리스트
      return state.status.list
    },
    getView(state){
      //강의동영상 상세
      return state.status.view
    },
  }
}