
import {DEFAULT_LAYOUT, NONE_LAYOUT} from '@/layouts';

export const MAIN_SCREEN = 'Main'
export const LOGIN_SCREEN = 'Login'
export const GUEST_SCREEN = 'Guest'
export const FINDID_SCREEN = 'FindId'
export const RESULTID_SCREEN = 'ResultId'
export const FINDPW1_SCREEN = 'FindPw1'
export const FINDPW2_SCREEN = 'FindPw2'
export const FINDPW3_SCREEN = 'FindPw3'
export const JOIN1_SCREEN = 'join1'
export const JOIN2_SCREEN = 'join2'
export const JOIN3_SCREEN = 'join3'
export const JOIN4_SCREEN = 'join4'
export const TERMS_SCREEN = 'Terms'
export const PRIVACY_SCREEN = 'Privacy'
export const REFUND_SCREEN = 'Refund'
export const MY_COMMUNITY_LIST_SCREEN = 'MyCommunityList'
export const NOTICE_LIST_SCREEN = 'NoticeList'
export const NOTICE_VIEW_SCREEN = 'NoticeView'
export const INQUIRY_WRITE_SCREEN = 'InquiryWrite'
export const INQUIRY_LIST_SCREEN = 'InquiryList'
export const INQUIRY_VIEW_SCREEN = 'InquiryView'
export const FAQ_SCREEN = 'Faq'
export const ALARM_LIST_SCREEN = 'AlarmList'
export const MAIN_LIST_SCREEN = 'MainList'
export const TAB_LIST_SCREEN = 'TabList'
export const COMMUNITY_WRITE_SCREEN = 'CommunityWrite'
export const COMMUNITY_VIEW_SCREEN = 'CommunityView'
export const MYPAGE_SCREEN = 'MyPage'
export const MYINFO_SCREEN = 'MyInfo'
export const PAYMENT_SCREEN = 'Payment'
export const PAY_LIST_SCREEN = 'PayList'
export const EXAM_SCREEN = 'Exam'
export const EXPLAN_SCREEN = 'Explan'
export const EXAM_LIST_SCREEN = 'ExamList'
export const NOTE_SCREEN = 'Note'
export const STATS_SCREEN = 'Stats'
export const STATS_VIEW_SCREEN = 'StatsView'
export const HP_VALIDATION_SCREEN = 'HpValidation'
export const PAY_INFO_SCREEN = 'PayInfo'
export const LECTURE_LIST_SCREEN = 'LectureList'
export const LECTURE_VIEW_SCREEN = 'LectureView'

export const Screens = new Map()
Screens.set(MAIN_SCREEN, import( '@/views/Main.vue')) //메인
Screens.set(LOGIN_SCREEN, import( '@/views/member/Login.vue')) //로그인
Screens.set(GUEST_SCREEN, import( '@/views/popup/Guest.vue')) //게스트 홈
Screens.set(FINDID_SCREEN, import( '@/views/account/FindId.vue')) //아이디 찾기
Screens.set(RESULTID_SCREEN, import( '@/views/account/ResultId.vue')) //아이디 찾기 결과
Screens.set(FINDPW1_SCREEN, import( '@/views/account/FindPw1.vue')) //비밀번호 찾기1
Screens.set(FINDPW2_SCREEN, import( '@/views/account/FindPw2.vue')) //비밀번호 찾기2
Screens.set(FINDPW3_SCREEN, import( '@/views/account/FindPw3.vue')) //비밀번호 찾기3
Screens.set(JOIN1_SCREEN, import( '@/views/account/Join1.vue')) //약관동의
Screens.set(JOIN2_SCREEN, import( '@/views/account/Join2.vue')) //회원가입1
Screens.set(JOIN3_SCREEN, import( '@/views/account/Join3.vue')) //회원가입2
Screens.set(JOIN4_SCREEN, import( '@/views/account/Join4.vue')) //회원가입 완료
Screens.set(TERMS_SCREEN, import( '@/views/account/Terms.vue')) //이용약관
Screens.set(PRIVACY_SCREEN, import( '@/views/account/Privacy.vue')) //개인정보 처리방침
Screens.set(REFUND_SCREEN, import( '@/views/account/Refund.vue')) //환불약관
Screens.set(MY_COMMUNITY_LIST_SCREEN, import( '@/views/community/MyCommunityList.vue')) //내커뮤니티 내역
Screens.set(NOTICE_LIST_SCREEN, import( '@/views/board/NoticeList.vue')) //공지사항 리스트
Screens.set(NOTICE_VIEW_SCREEN, import( '@/views/board/NoticeView.vue')) //공지사항 상세
Screens.set(INQUIRY_WRITE_SCREEN, import( '@/views/board/InquiryWrite.vue')) //1:1문의 등록
Screens.set(INQUIRY_LIST_SCREEN, import( '@/views/board/InquiryList.vue')) //1:1문의 리스트
Screens.set(INQUIRY_VIEW_SCREEN, import( '@/views/board/InquiryView.vue')) //1:1문의 상세
Screens.set(FAQ_SCREEN, import( '@/views/board/FaQ.vue')) //FAQ
Screens.set(ALARM_LIST_SCREEN, import( '@/views/board/AlarmList.vue')) //알림함
Screens.set(MAIN_LIST_SCREEN, import( '@/views/community/MainList.vue')) //커뮤니티 메인
Screens.set(TAB_LIST_SCREEN, import( '@/views/community/TabList.vue')) //커뮤니티 카테고리별 메인
Screens.set(COMMUNITY_WRITE_SCREEN, import( '@/views/community/CommunityWrite.vue')) //커뮤니티 글 등록
Screens.set(COMMUNITY_VIEW_SCREEN, import( '@/views/community/CommunityView.vue')) //커뮤니티 글 상세
Screens.set(MYPAGE_SCREEN, import( '@/views/member/MyPage.vue')) //마이페이지
Screens.set(MYINFO_SCREEN, import( '@/views/member/MyInfo.vue')) //내정보 수정
Screens.set(PAYMENT_SCREEN, import( '@/views/member/Payment.vue')) //결제
Screens.set(PAY_LIST_SCREEN, import( '@/views/member/PayList.vue')) //결제 리스트
Screens.set(EXAM_SCREEN, import( '@/views/quiz/Exam.vue')) //문제
Screens.set(EXPLAN_SCREEN, import( '@/views/quiz/Explan.vue')) //문제해설
Screens.set(EXAM_LIST_SCREEN, import( '@/views/quiz/ExamList.vue')) //문제풀이내역
Screens.set(NOTE_SCREEN, import( '@/views/quiz/Note.vue')) //오답노트
Screens.set(STATS_SCREEN, import( '@/views/quiz/Stats.vue')) //시험 통계
Screens.set(STATS_VIEW_SCREEN, import( '@/views/quiz/StatsView.vue')) //시험 통계 상세
Screens.set(HP_VALIDATION_SCREEN, import( '@/views/account/HpValidation.vue')) //휴대전화 인증
Screens.set(PAY_INFO_SCREEN, import( '@/views/popup/PayInfo.vue')) //결제안내팝업
Screens.set(LECTURE_LIST_SCREEN, import( '@/views/board/LectureList.vue')) //강의동영상 리스트
Screens.set(LECTURE_VIEW_SCREEN, import( '@/views/board/LectureView.vue')) //강의동영상 상세

export const keysForMeta = (key) => {
  switch (key){
    case PAY_LIST_SCREEN:
    case PAYMENT_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '이용권 결제',
      }
    case MYINFO_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '내정보 수정',
      }
    case COMMUNITY_WRITE_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '커뮤니티 작성하기',
      }
    case ALARM_LIST_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '알림',
      }
    case FAQ_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: 'FAQ',
      }
    case INQUIRY_VIEW_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '1:1 문의',
      }
    case MY_COMMUNITY_LIST_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '내커뮤니티 내역',
      }
    case NOTICE_VIEW_SCREEN:
    case NOTICE_LIST_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '공지사항',
      }
    case LECTURE_LIST_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
        title: '강의동영상',
      }
    case REFUND_SCREEN:
    case PRIVACY_SCREEN:
    case TERMS_SCREEN:
    case JOIN4_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_close: true
      }
    case RESULTID_SCREEN:
    case HP_VALIDATION_SCREEN:
    case STATS_VIEW_SCREEN:
    case STATS_SCREEN:
    case MYPAGE_SCREEN:
    case COMMUNITY_VIEW_SCREEN:
    case INQUIRY_LIST_SCREEN:
    case INQUIRY_WRITE_SCREEN:
    case JOIN3_SCREEN:
    case JOIN2_SCREEN:
    case JOIN1_SCREEN:
    case FINDPW1_SCREEN:
    case FINDPW2_SCREEN:
    case FINDPW3_SCREEN:
    case FINDID_SCREEN:
    case LECTURE_VIEW_SCREEN:
      return {
        layout: DEFAULT_LAYOUT,
        btn_back: true,
      }
    default:
      return {
        layout: NONE_LAYOUT,
      }
  }
}