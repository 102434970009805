import {createStore} from 'vuex'
import { auth } from '@/store/modules/auth.module'
import createPersistedState from 'vuex-persistedstate';
import {enCrypted, deCrypted} from '@/utils/cryptJs';
import {alarm} from '@/store/modules/alarm.module';
import {schedule} from '@/store/modules/schedule.module';
import {banner} from '@/store/modules/banner.module';
import {exam} from '@/store/modules/exam.module';
import {inapp} from '@/store/modules/inapp.module';
import {inquiry} from '@/store/modules/inquiry.module';
import {notice} from '@/store/modules/notice.module';
import {faq} from '@/store/modules/faq.module';
import {community} from '@/store/modules/community.module';
import {lecture} from '@/store/modules/lecture.module';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    alarm,
    schedule,
    banner,
    exam,
    inapp,
    inquiry,
    notice,
    faq,
    community,
    lecture
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
      storage: {
        getItem: key => {
          try {
            return deCrypted(localStorage.getItem(key))
          } catch (e) {
            return null;
          }
        },
        setItem: (key, value) => {
          localStorage.setItem(key, enCrypted(value))
        },
        removeItem: key => {
          localStorage.removeItem(key)
        }
      }
    })
  ]
})
